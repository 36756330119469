import './index.module.scss';
import Layout from '../../../layout';
import Header from '../../../components/header/header';
import ContactInfoProps from '../../../components/contact-info/contact-info';
import Footer from '../../../components/footer/footer';
import BannerInner from '../../../components/banner-inner/banner-inner';
import ContentInner from '../../../components/content-inner/content-inner';
import ContactForm from '../../../components/contact-form/contact-form';
import dollarCoin from '../../../assets/images/dollar-coin.svg';
import diamondIcon from '../../../assets/images/diamond-icon.svg';
import vasIcon from '../../../assets/images/vas.svg';
import umbrellaIcon from '../../../assets/images/umbrella-icon.svg';
import treeIcon from '../../../assets/images/tree-icon.svg';
import banner from '../../../assets/images/individual.jpg';

/* eslint-disable-next-line */
export interface IndexProps {}

export function Index(props: IndexProps) {
  return (
    <Layout>
      <Header />
      <BannerInner
        bannerImage={banner}
        caption={
          <h2 className="x-large-heading color-white">
            Bespoke Wealth Management Solutions
          </h2>
        }
      />
      <ContentInner
        heading="Create a Life Plan and make your dreams a reality."
        content="We all have dreams, and we want to help you reach yours. That’s why we will develop a wealth management strategy specifically for you, based solely on what is important to you-no cookie cutter approach or one-size-fits-all money management. We look at the entire picture of your life; not just your assets, but your goals and concerns, fears and dreams. Then we will put together a path designed just for you alone. And if you stray from the path for whatever reasons, we are here to get you back on it. "
        subheading="We will work with you to create a comprehensive asset management plan including:"
        subContent={
          <div>
            <a
              href={
                '/our-services/services-for-Individuals/wealth-planning-services'
              }
              className="small-heading color-theme icon-small mar-top-rem "
            >
              {' '}
              <i className="icon-head">
                <img src={dollarCoin} alt="coin icon" />
              </i>{' '}
              Wealth Planning{' '}
            </a>{' '}
            <p className="norma-size-font">
              DGM will partner with you on all aspects of wealth management,
              including cash flow and income analysis, tax planning, benefits
              optimization, retirement solutions, 529 plans, education savings
              strategies and retirement solutions to help you achieve your
              goals.
            </p>
            <a
              href={
                '/our-services/services-for-Individuals/portfolio-management'
              }
              className="small-heading color-theme icon-small mar-top-rem"
            >
              {' '}
              <i className="icon-head">
                <img src={diamondIcon} alt="coin icon" />
              </i>{' '}
              Portfolio Management{' '}
            </a>
            <p className="norma-size-font">
              We will design a portfolio based upon your goals, risk tolerance,
              and time frame for reaching certain thresholds.
            </p>
            <a
              href={'/our-services/services-for-Individuals/estate-planning'}
              className="small-heading color-theme icon-small mar-top-rem"
            >
              {' '}
              <i className="icon-head">
                <img src={vasIcon} alt="coin icon" />
              </i>{' '}
              Estate Planning{' '}
            </a>
            <p className="norma-size-font">
              We have trusted partners who specialize in designing an estate
              plan that includes reviewing and providing advice on wills,
              trusts, power of attorneys and health care directives to ensure
              your legacy is set and your wishes are met.
            </p>
            <a
              href={
                '/our-services/services-for-Individuals/insurance-and-tax-strategies'
              }
              className="small-heading color-theme icon-small mar-top-rem"
            >
              {' '}
              <i className="icon-head">
                <img src={umbrellaIcon} alt="coin icon" />
              </i>{' '}
              Insurance and Tax Strategies{' '}
            </a>
            <p className="norma-size-font">
              Podcasting operational change management inside of workflows to
              establish a framework. Taking seamless key performance indicators
              offline to maximise the long tail. Keeping your eye on the ball
              while performing a deep dive on the start-up mentality to derive
              convergence on cross-platform integration.
            </p>
            <a
              href={
                '/our-services/services-for-Individuals/socially-responsible-investing'
              }
              className="small-heading color-theme icon-small mar-top-rem"
            >
              {' '}
              <i className="icon-head">
                <img src={treeIcon} alt="coin icon" />
              </i>{' '}
              Socially Responsible Investing{' '}
            </a>
            <p className="norma-size-font">
              Collaboratively administrate empowered markets via plug-and-play
              networks. Dynamically procrastinate B2C users after installed base
              benefits. Dramatically visualize customer directed convergence
              without revolutionary ROI.
            </p>
          </div>
        }
      />
      <ContactForm />
      <ContactInfoProps />
      <Footer />
    </Layout>
  );
}

export default Index;
